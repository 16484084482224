const next = $(".next"),
    input01 = $('input[name="was"]'),
    input02 = $('input[name="wer"]'),
    input03 = $('input[name="probleme"]'),
    input04 = $('input[name="hoergeraet"]'),
    input05 = $('input[name="alter"]'),
    input06 = $('input[name="plz"]'),
    input07 = $('input[name="plz"]'),
    input08 = $('input[name="mail"]'),
    inactive = $(".next.inactive"),
    input = $('input[type="radio"]');

function handleCookieBanner(e, n, t, o, i) {
    e.outerHeight();
    o || (o = 500), i && e.addClass("cookie"), "overContent" == n ? (jQuery("body").hasClass("cookie-banner-overContent") || (jQuery("body").addClass("cookie-banner-overContent"), e.data("type", "overContent")), "show" == t ? (e.slideToggle(o), document.getElementById("footerCookie").classList.add("footer-cookie")) : "hide" == t ? e.slideToggle(o, function() {
        jQuery("body").removeClass("cookie-banner-overContent"), jQuery("body").removeClass("cookie-banner-active"), document.getElementById("footerCookie").classList.remove("footer-cookie")
    }) : console.log("unknown status")) : console.log("unknown type")
}

function appendJS() {
    jQuery.ajax({
        url: "./includes/tracking.html",
        cache: !0
    }).done(function(e) {
        jQuery("head").append(e)
    })
}
next.on("click", function() {
    0 == $(this).hasClass("inactive") && ($(this).parent().removeClass("active"), $(this).parent().next().addClass("active"))
}), input.on("change", function() {
    $(this).parent().parent().next(".btn-red").removeClass("inactive")
}), inactive.on("click", function() {
    $(this).hasClass("inactive") && console.log("Bitte treffen Sie eine gültige Auswahl!")
}), $(function() {
    "use strict";
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//, "") === this.pathname.replace(/^\//, "") && location.hostname === this.hostname) {
            var e = $(this.hash);
            if ((e = e.length ? e : $("[name=" + this.hash.slice(1) + "]")).length) return $("html, body").animate({
                scrollTop: e.offset().top
            }, 1e3), !1
        }
    })
});

var setCookie = !0;

function setStatusCookie(e, n, t) {
    var o = new Date;
    o.setTime(o.getTime() + 31536e6);
    var i = "expires='" + o.toUTCString() + "';";
    t && (i = ""), document.cookie = e + "=" + n + ";" + i + "path=/"
}

function cookieAlreadySet(e) {
    for (var n = document.cookie.split(";"), t = !1, o = 0; o < n.length; o++) {
        n[o].split("=")[0].trim() == e && (t = !0)
    }
    return t
}
jQuery(document).on("click", ".cookie-banner-close", function(e) {
    e.preventDefault(), e.stopPropagation(), jQuery(this).closest(".cookie-banner").hasClass("cookie") && (setStatusCookie("accepted", 1, !1), appendJS()), handleCookieBanner(jQuery(this).closest(".cookie-banner"), jQuery(this).closest(".cookie-banner").data("type"), "hide")
}), jQuery(window).on("load resize orientationchange", function(e) {
    if ("load" == e.type) {
        cookieAlreadySet("accepted");
        setCookie && !cookieAlreadySet("accepted") && (setCookie = !1, handleCookieBanner(jQuery(".cookie-banner"), "overContent", "show", !1, !0))
    }
}), jQuery(document).ready(function(e) {
    1 == cookieAlreadySet("accepted") && appendJS()
});